import React from "react"

import view from "./formattedtext.module.scss"

function FormattedTextBlocks({ data }) {
  const { paragraph, order } = data

  const renderParagraph = () => {
    return (
      <>
       {paragraph != '' ? <div dangerouslySetInnerHTML={{__html: paragraph}} /> : <span></span>}
      </>
    )
  }

  return (
    <div id={"formatText-" + order} className={view["format-wrap"]}>
        <div className={view["format-content"]}>{renderParagraph()}</div>
    </div>
  )
}

FormattedTextBlocks.defaultProps = {
  data: {
    paragraph: ''
  }
}

export default FormattedTextBlocks