import React from "react"

import view from "./headerimage.module.scss"
import { Link } from "gatsby";
function ImageParagraphComponent({ data }) {
  //headerImage, headerTitle, order, visible
  const { headerImage, headerTitle } = data

  const renderImage = () => {
    return headerImage != '' 
    ? "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)), url(" + headerImage + ") center / cover no-repeat;"
    : "transparent"
  }

  const renderTitle = () => {
    return (
      <>
        {headerTitle != '' ? <h1 className={view["header-title"]}>{headerTitle}</h1> : <span></span>}
      </>
    )
  }

  return (
    <section id="contentHeader" className={view["header-wrap"]}>
        <div className={view["header-banner"]} style={{background: renderImage()}}>
          {renderTitle()}
        </div>
    </section>
  )
}

ImageParagraphComponent.defaultProps = {
  data: {
    headerImage: '',
    headerTitle: '',
  }
}

export default ImageParagraphComponent