import React from "react"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import HTML from "../components/HTML"
import HeaderImageComponent from "../components/image/HeaderImageComponent"
import FormattedTextBlocks from "../components/formattedText/FormattedTextBlocks"
import MediaGalleryComponent from "../components/media/MediaGalleryComponent"
import AddtlButtons from "../components/additionalButtons/AddtlButtonsComponent"
import Directions from "../components/directions/Directions"

function SimpleReadable(props) {
  const { pageContext } = props
  const { pageContent, theme,dealerInfo,inventorymanagement,lead_url,site_id, headerTags } = pageContext
  const dealerHours = dealerInfo
  const dealerLoc = dealerInfo
  const document = typeof window !== 'undefined' && window.document


  let components = [
    <HeaderImageComponent  data={pageContent.HeaderImageComponent}/>,
    <MediaGalleryComponent data={pageContent.MediaGalleryComponent}/>, //content[galleryImage, imageAlt] order, visible
    <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} tagString={headerTags}/>,
    <Directions key={"DirectionsHours"}
    data={{...pageContent.DirectionsHours, ...dealerInfo }}/>,
    <AddtlButtons disclaimerContact={inventorymanagement.disclaimerContact} data={pageContent.AddtlButtonsComponent} leadURL={lead_url} site_id={site_id} />
  ]

  if(pageContent.hasOwnProperty("FormattedTextComponent")) {
     const { formattedtext } = pageContent.FormattedTextComponent
    const rawBlocks = formattedtext.map(element => {
        return <FormattedTextBlocks data={{
            order: Number(element.order),
            visible: element.visible,
            paragraph: element.paragraph}} />
    })
    components.push(...rawBlocks)
}

  if(pageContent.hasOwnProperty("ArrayRawHTML")) {
    const { arrayRawHTML } = pageContent.ArrayRawHTML
    const rawComponents = arrayRawHTML.map(element => {  
        return <HTML data={{ 
            order: Number(element.order),
            visible: element.visible,
            rawHTML: element.rawHtml
        }} key={"HTML"} />
    })
    components.push(...rawComponents)
}

let visibleComponents = components.filter(component => {
  return component.props.data.visible === true
})

  let sortedComponents = visibleComponents.sort((a, b) =>
     parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ) // sorts the components based on json data
  // filters out the components that are not visible

  return (
    <Layout>
      { sortedComponents}
    </Layout>
  )
}

export default SimpleReadable
