import React from "react"
import view from "./mediagallery.module.scss"

function MediaGalleryComponent({ data }) {
  const { mediagallery, slideshowGallery } = data;

  function updateMGImageCarousel(target) {
    const MGImageCarouselItems = Array.from(target.children);

    MGImageCarouselItems.forEach((item, index) => {
        item.classList.remove('active');
        item.style.opacity = '0';
        item.style.visibility = 'hidden';
    });

    MGImageCarouselItems[currentIndex].classList.add('active');
    MGImageCarouselItems[currentIndex].style.opacity = '1';
    MGImageCarouselItems[currentIndex].style.visibility = 'visible';
  }

  let currentIndex = 0;

  return (
      <section className={view["media-gallery"]}>
        { !slideshowGallery 
        ?  <ul className={view["mg-list"]}>
            { mediagallery.map((element, idx) => {
              return <li id={"mg-"+idx} className={view["mg-item"]}>
                <img className={view["mg-image"]} src={element.galleryImage} alt={element.imgAlt} />
              </li>
            })}
          </ul> 
        :
         mediagallery.length > 1 
          ? <div className={view["MGImageCarousel-container"]}>
              <div className={view["MGImageCarousel-wrapper"]}>
                  <div class={view["MGImageCarousel"]}>
                    {mediagallery.map((element, idx) => {
                      return <div id={"mg-"+idx} className={view["MGImageCarousel-item"]}>
                        <div className={view["image-carousel-styling"]} style={{background: "url(" + element.galleryImage + ") center / cover"}} alt={element.imgAlt}></div>
                        </div>
                    })}
                  </div>
                  
              </div>
              <div className={view["MGImageCarousel-navigation"]}>
                  <div className={view["navPrev"]} onClick={function(e) {let element =  e.target.parentNode.parentNode.firstElementChild.firstElementChild; currentIndex = (currentIndex > 0) ? currentIndex - 1 : element.children.length - 1; updateMGImageCarousel(element);}}></div>
                  <div className={view["navNext"]} onClick={function(e) {let element =  e.target.parentNode.parentNode.firstElementChild.firstElementChild; currentIndex = (currentIndex < element.children.length - 1) ? currentIndex + 1 : 0; updateMGImageCarousel(element)}}></div>
              </div>
          </div>
          : mediagallery.map((element, idx) => { return <img className={view["solo-image"]} src={element.galleryImage} alt={element.imgAlt} /> })
        }
      </section>
  )
}

MediaGalleryComponent.defaultProps = {
  data: {
    content: []
  }
}

export default MediaGalleryComponent